
    <div id="wrapper">

            <!-- header begin -->
            <header class="transparent">
                <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="de-flex sm-pt10">
                            <div class="de-flex-col">
                                <div class="de-flex-col">
                                    <!-- logo begin -->
                                    <div id="logo">
                                        <a href="#">
                                            <img *ngIf="isView == 'mobView'" style="width: 71px;  bottom: 19px;position: relative;" alt="" src="assets/images/shumo-sm-mb.png" />
                                            <img *ngIf="isView == 'deskView'" alt="" src="assets/images/shumo-sm.png" />
                                          
                                        </a>
                                    </div>
                                    <!-- logo close -->
                                </div>
                                <div class="de-flex-col">
                                 
                                </div>
                            </div>
                            <div class="de-flex-col header-col-mid">
                                <!-- mainmenu begin -->
                                <ul id="mainmenu">
                                  <li>
                                      <a href="index.html">Home<span></span></a>

                                  </li>

                                 
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </header>
            <!-- header close -->
        <!-- content begin -->
        <div class="no-bottom no-top" id="content">
            <div id="top"></div>

            <!-- section begin -->
            <section id="subheader">
                    <div class="center-y relative text-center">
                        <div class="container">
                            <div class="row">

                                <div class="col-md-12 text-center">
									<h1>CLAIM $SHUMO</h1>
                                    <p style="color: red;">*Only for Private Sale Investors.</p>
                                    <p> Different investment rounds have different unlocking schedules. You can now claim your precious SHUMO.

                                        <!-- <br>Thank you for being a true hodler</p> -->
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="row">

                                <div class="col-md-12 text-center">
                                    <button class="btn-main" id="btn-metamask" (click)="CONNECT_WALLET()"> 
                                        <i class="icon_wallet_alt"></i><span>{{connectButton}}</span> </button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
            </section>
            <!-- section close -->


            <!-- section begin -->
			<section aria-label="section">
				<div class="container">
					<div class="row" style="align:center;">

						<div class="col-md-2"></div>
						<div class="col-md-8">
                            <ul class="activity-list">

                                <li class="act_follow">
                                    <img class="lazy" src="assets/images/author/shumo-sm.png" alt="">
                                    <div class="act_list_text">
                                        <h4>Wallet Address</h4>
                                       
                                         <span id="spnadrs" class="act_list_date" *ngIf="isView == 'deskView'">
                                            {{this.accounts || 'None'}}
                                         </span>
                                         <span id="spnadrs" class="act_list_date" *ngIf="isView == 'mobView'">
                                             {{this.accounts.substring(0, 4) +'....'+ this.accounts.substr(this.accounts.length - 4)}}
                                         </span>
                                        <span class="act_list_date">
                                            {{claimdesc}}
                                        </span>
                                    </div>
                                </li>
                                <li class="act_sale">
                                    <img src="assets/images/items/token.png" alt="">
                                    <div class="act_list_text">
                                      <h4>Tokens To Claim</h4>
                                       <a href="#">{{tokenAmount || '0'}}</a>
                                      <span class="act_list_date">
                                          
                                      </span>
                                    </div>
                                </li>
                            </ul>

                            <button class="btn-main danger-2" style="width:300px;" [disabled]="!enableClaim" (click)="claimtoken()"> 
                                <i class="icon_wallet_alt"></i><span>{{claimdesc}} </span> </button> <br *ngIf="isView == 'mobView' && showLoader">
                    <div class="row" *ngIf="showLoader">
                        <div class="col-md-4"></div>
                        <div class="col-md-4" style="text-align: center;">
                            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                        </div>
                        <div class="col-md-4"></div>
                                                </div>                 
                                <br>
                                <span class="hashValueDesk" *ngIf="isView == 'deskView' && hashValue">{{hashValue}}</span>
                                <span class="hashValueDesk" *ngIf="isView == 'mobView' && hashValue">
                                    {{hashValue.substring(0, 12) +'....'+ hashValue.substr(hashValue.length - 12)}}
                                </span>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="spacer-2"></div>
                       


					</div>

				</div>
			</section>
			<!-- section close -->

        </div>
        <!-- content close -->

        <a href="#" id="back-to-top"></a>

        <!-- footer begin -->
        <footer>
            <div class="container">
                <div class="row">

                    <div class="col-md-6 col-sm-6 col-xs-1 desktopheading">
                        <div class="widget">
                            <h2 style="font-weight: bold;">
                                Join Us
                            </h2>
                            <h5>Socials</h5>
                            <div class="social-icons">
                                <a href="https://t.me/shumoofficial"><i class="fa fa-telegram fa-lg"></i></a>
                                <a href="https://twitter.com/shumoofficial"><i class="fa fa-twitter fa-lg"></i></a>
                               
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-xs-1 mobileheading" style="text-align: center;">
                        <div class="widget">
                            <h2 style="font-weight: bold;">
                                Join Us
                            </h2>
                            <h5>Socials</h5>
                            <div class="social-icons">
                              <a href="https://t.me/shumoofficial"><i class="fa fa-telegram fa-lg"></i></a>
                                <a href="https://twitter.com/shumoofficial"><i class="fa fa-twitter fa-lg"></i></a>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 col-sm-6 col-xs-1" style="text-align: center">
                      <div class="container float-end">
                        <div class="widget">
                            <h5>Support</h5>
                            <p>For Questions & Collabs?</p>
                            <p> @email</p>

                        </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="subfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="de-flex">
                                <div class="de-flex-col">
                                    <a href="#">
                                        <span class="copy">Shumo @ 2022</span>
                                    </a>
                                </div>
                                <div class="de-flex-col">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- footer close -->

    </div>

    